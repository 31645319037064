import React , { useState, useEffect } from 'react'
import TableInc from '../../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../../lib/requests.es6';

const LeviesTables = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const { levies } = props.membership_configs.content;

      const mappedData = levies.map(levy => ({
        name: levy.name,
        rate: levy.rate,
      }));


      setData(mappedData);
    }
  }, [props.membership_configs]);

  const updateMembershipConfigs = async (newData) => {
    const adjustedData = newData.map(rate => ({
      ...rate,
      rate: (rate.rate / 100)
    }));

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        levies: adjustedData
      }
    }
    console.log("payload", payload)
    await updateExtendedConfig(payload)
    window.location.reload(true);
  };

  return (
    <TableInc
      title="Levies"
      columns={[
        { title: 'name', field: 'name' },
        { title: 'Rate (%)', field: 'rate' },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length > 0 ? data.length : 5, // Show 5 rows or based on data length
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            console.log("New Data Added:", newData);

            const newDataArray = [...data, newData];
            console.log("newDataArray", newDataArray)
            setData(newDataArray);
            updateMembershipConfigs(newDataArray);
            resolve();

          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            console.log("Row Updated:", newData);
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.benefit_amount = newData.benefit_amount * 100;
            dataUpdate[index] = newData;
            setData(dataUpdate);
            updateMembershipConfigs(dataUpdate);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            console.log("Row Deleted:", oldData);
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData(dataDelete);
            updateMembershipConfigs(dataDelete);
            resolve();
            resolve();
          }),
      }}
    />
  )
}

export default LeviesTables