import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';

const ClaimDetails = (props) => {

  //hospital details 
  const claimant_full_name = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_full_name
    : 'N/A';

  const claimant_phone_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_phone_number
    : 'N/A';

  const claimant_id_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_id_number
    : 'N/A';

  const claimant_relationship_to_patient = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_relationship_to_patient
    : 'N/A';

  const patientAge = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.age
    : 'N/A';



  const admission_date = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.admission_date
    : 'N/A';

  const discharge_date = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.discharge_date
    : 'N/A';

  const number_of_days_in_hospital = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.number_of_days_in_hospital
    : 'N/A';


  //death details 
  const claimant_full_name_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_full_name
    : 'N/A';

  const claimant_id_number_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_id_number
    : 'N/A';

  const claimant_phone_number_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_phone_number
    : 'N/A';

  const claimant_relationship_to_patient_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_relationship_to_patient
    : 'N/A';

  const currencyCode = getPartner().country.currency_code


  //funeral details
  const funeral_death_date = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.date_of_death
    : 'N/A';

  const funeral_home_name = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.funeral_home_name
    : 'N/A';

  const death_certficate_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.death_certficate_number
    : 'N/A';

  //device damage details
  const device_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.device_number
    : 'N/A';

  const date_of_damage = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.date_of_damage
    : 'N/A';

  //disability details
  const date_of_disability = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.date_of_disability
    : 'N/A';

  //device theft details
  const date_of_theft = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.date_of_theft
    : 'N/A';

  const classes = useStyle();

  if (!props.claim.claim_details) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center' }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }




  return (
    <>
      <>
        {
          props.isHospitalClaim(props.claim) ? (<>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Patient</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Patient Full Name</h4>
                    <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Patient D.O.B</h4>
                    <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                  </Grid>

                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Patient National ID</h4>
                    <p>{props.claim.claim_details.dependant_national_id || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Patient Age</h4>
                    <p>{patientAge}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Admission Date</h4>
                    <p>{formatDateLocale(admission_date)}</p>
                  </Grid>
                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Discharge Date</h4>
                    <p>{formatDateLocale(discharge_date)}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Number Of Days In Hospital </h4>
                    <p>{number_of_days_in_hospital}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Hospital Attended</h4>
                    <p>{props.claim.claim_details.additional_details.hospital_attended || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Cause Of Hospitilization</h4>
                    <p>{props.claim.claim_details.additional_details.cause_of_hospitilization || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Inpatient Number</h4>
                    <p>{props.claim.claim_details.additional_details.inpatient_number || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Weakly Loan Amount</h4>
                    <p>  {`${currencyCode} ${props.claim.claim_details.additional_details.weakly_loan_amount.toLocaleString()}`}</p>
                  </Grid>
              
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>) : props.isDeathClaim(props.claim) ? (<>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Deceased Names</h4>
                    <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Relation to Principal</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Date of Birth</h4>
                    <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Date of Death</h4>
                    <p>{formatDateLocale(props.claim.claim_details.additional_details.date_of_death) || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Deceased Age</h4>
                    <p>{props.claim.claim_details.additional_details.age || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Cause of Death </h4>
                    <p>{props.claim.claim_details.additional_details.cause_of_death || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Suffered any Chronic Condition? </h4>
                    <p>{props.claim.claim_details.additional_details.suffered_chronic_condition === true ? "Yes" : "No"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Hospitalized? </h4>
                    <p>{props.claim.claim_details.additional_details.hospitalized === true ? "Yes" : "No"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Benefit Amount </h4>
                    <p>{`${currencyCode} ${(props.claim.benefits[0].max_benefit_amount_in_cents / 100).toLocaleString()}` || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name </h4>
                    <p>{claimant_full_name_death}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant ID Number </h4>
                    <p>{claimant_id_number_death}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Phone Number </h4>
                    <p>{claimant_phone_number_death}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Relationship To Deceased </h4>
                    <p>{claimant_relationship_to_patient_death}</p>
                  </Grid>



                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Outstanding Loan Amount At Date Of Event </h4>
                
                    <p>{`${currencyCode} ${(props.claim.claim_details.additional_details.outstanding_loan_amount_at_date_of_event).toLocaleString()}` || "N/A"}</p>

                  </Grid>

                  <div className={classes.borderbottom}></div>

                </Grid>
              </Grid>

              <Grid container spacing={2} className={classes.notesheader}>
                <Grid item xs={12} md={12} sm={12}>
                  <ul>
                    <li><h4>Claim Notes</h4></li>
                    <li >
                      <Tooltip title="Add claim Notes">
                        <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                      </Tooltip>
                    </li>
                  </ul>

                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.noteswrapper}>
                <Grid item xs={12} md={4} sm={4}>
                  <div className={classes.notesbox}>
                    <p>{props.claim.notes || "No claim notes available"}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>


          </>)  :  props.isFuneralClaim(props.claim) ? (<>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Deceased Names</h4>
                    <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Relation to Principal</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Date of Birth</h4>
                    <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Date of Death</h4>
                    <p>{formatDateLocale(funeral_death_date) || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Deceased Age</h4>
                    <p>{props.claim.claim_details.additional_details.age || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Cause of Death </h4>
                    <p>{props.claim.claim_details.additional_details.cause_of_death || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Suffered any Chronic Condition? </h4>
                    <p>{props.claim.claim_details.additional_details.suffered_chronic_condition === true ? "Yes" : "No"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Hospitalized? </h4>
                    <p>{props.claim.claim_details.additional_details.hospitalized === true ? "Yes" : "No"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Benefit Amount </h4>
                    <p>{`${currencyCode} ${(props.claim.benefits[0].max_benefit_amount_in_cents / 100).toLocaleString()}` || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name </h4>
                    <p>{claimant_full_name_death}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant ID Number </h4>
                    <p>{claimant_id_number_death}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Phone Number </h4>
                    <p>{claimant_phone_number_death}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Relationship To Patient </h4>
                    <p>{claimant_relationship_to_patient_death}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Funeral Home Name </h4>
                    <p>{funeral_home_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Death Certficate Number </h4>
                    <p>{death_certficate_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>
              </Grid>

              <Grid container spacing={2} className={classes.notesheader}>
                <Grid item xs={12} md={12} sm={12}>
                  <ul>
                    <li><h4>Claim Notes</h4></li>
                    <li >
                      <Tooltip title="Add claim Notes">
                        <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                      </Tooltip>
                    </li>
                  </ul>

                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.noteswrapper}>
                <Grid item xs={12} md={4} sm={4}>
                  <div className={classes.notesbox}>
                    <p>{props.claim.notes || "No claim notes available"}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </>) : props.isDeviceDamageClaim(props.claim) ? (<>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Policy Holder</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>



                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Date Of Damage</h4>
                    <p>{date_of_damage}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Device Number</h4>
                    <p>{device_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </>) : props.isParmanentDisabilityClaim(props.claim) ? (<>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Policy Holder</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>



                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Disability Date </h4>
                    <p>{date_of_disability}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Hospital Attended </h4>
                    <p>{props.claim.claim_details.additional_details.hospital_attended || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Nature Of Illnes Or Disability </h4>
                    <p>{props.claim.claim_details.additional_details.nature_of_illnes_or_disability || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Patient Number</h4>
                    <p>{props.claim.claim_details.additional_details.patient_number || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Outstanding Loan Amount At Date Of Event </h4>
                    <p>{`${currencyCode} ${(props.claim.claim_details.additional_details.outstanding_loan_amount_at_date_of_event).toLocaleString()}` || "N/A"}</p>                
                      </Grid>
              
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </>) : props.isDeviceTheftClaim(props.claim) ? (<>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Policy Holder</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>



                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Date Of Theft</h4>
                    <p>{date_of_theft}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Device Number</h4>
                    <p>{device_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </>) :  props.isTemporaryDisability(props.claim) ? (<>
          
          
          
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Policy Holder</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>



                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4> Temporary Disability Date </h4>
                    <p>{date_of_disability}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          
          
          </>) :  props.isLastExpense(props.claim) ? (
          
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Deceased Names</h4>
                    <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Relation to Principal</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Date of Birth</h4>
                    <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Date of Death</h4>
                    <p>{formatDateLocale(props.claim.event_date) || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4> Deceased Age</h4>
                    <p>{props.claim.claim_details.additional_details.age || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Cause of Death </h4>
                    <p>{props.claim.claim_details.additional_details.cause_of_death || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Deceased Suffered any Chronic Condition? </h4>
                    <p>{props.claim.claim_details.additional_details.suffered_chronic_condition === true ? "Yes" : "No"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Hospitalized? </h4>
                    <p>{props.claim.claim_details.additional_details.hospitalized === true ? "Yes" : "No"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Benefit Amount </h4>
                    <p>{`${currencyCode} ${(props.claim.benefits[0].max_benefit_amount_in_cents / 100).toLocaleString()}` || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name </h4>
                    <p>{claimant_full_name_death}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant ID Number </h4>
                    <p>{claimant_id_number_death}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Phone Number </h4>
                    <p>{claimant_phone_number_death}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Relationship To Patient </h4>
                    <p>{claimant_relationship_to_patient_death}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>
              </Grid>

              <Grid container spacing={2} className={classes.notesheader}>
                <Grid item xs={12} md={12} sm={12}>
                  <ul>
                    <li><h4>Claim Notes</h4></li>
                    <li >
                      <Tooltip title="Add claim Notes">
                        <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                      </Tooltip>
                    </li>
                  </ul>

                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.noteswrapper}>
                <Grid item xs={12} md={4} sm={4}>
                  <div className={classes.notesbox}>
                    <p>{props.claim.notes || "No claim notes available"}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          
          
        ) :  props.isProperty(props.claim) ? (<>
        
        <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                    <h4>Policy Holder Full Names</h4>
                    <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Policy Holder  Number</h4>
                    <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant Full Name</h4>
                    <p>{claimant_full_name}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Claimant Phone Number</h4>
                    <p>{claimant_phone_number}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Claimant ID Number</h4>
                    <p>{claimant_id_number}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>claimant Relationship To Policy Holder</h4>
                    <p>{claimant_relationship_to_patient}</p>
                  </Grid>


                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Gender</h4>
                    <p>{props.claim.claim_details.gender || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Relationship To Policyholder</h4>
                    <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                  </Grid>



                  {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                  <div className={classes.borderbottom}></div>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Agent Name</h4>
                    <p>{props.claim.web_agent_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Type Of Business</h4>
                    <p>{props.claim.claim_details.additional_details.type_of_business || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                    <h4>Location Of Business</h4>
                    <p>{props.claim.claim_details.additional_details.location_of_business || "N/A"}</p>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                    <h4>Details Of Event</h4>
                    <p>{props.claim.claim_details.additional_details.details_of_event || "N/A"}</p>
                  </Grid>
                  <div className={classes.borderbottom}></div>

                </Grid>

                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </>) :    (<>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
                <Grid container spacing={2} className={classes.claimdetailswrapper}>



                </Grid>
                <Grid container spacing={2} className={classes.notesheader}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ul>
                      <li><h4>Claim Notes</h4></li>
                      <li >
                        <Tooltip title="Add claim Notes">
                          <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                        </Tooltip>
                      </li>
                    </ul>

                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.noteswrapper}>
                  <Grid item xs={12} md={4} sm={4}>
                    <div className={classes.notesbox}>
                      <p>{props.claim.notes || "No claim notes available"}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </>)
        }


      </>


    </>
  )
}

export default ClaimDetails