import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner } from '../../lib/access.es6';

const ViewVehicleDetailsInc = (props) => {
  console.log("props.vehicleDetails",  props.vehicleDetails.policy_schedule_entries[0].dependant.additional_details)
  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code
  const vehicleDetails = props.vehicleDetails  ? props.vehicleDetails.policy_schedule_entries[0].dependant.additional_details : null;


  if (props.vehicleDetails && vehicleDetails) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.policieswrapper}>
              <table>
                <thead>
                  <tr>
                    <th>Year Of Manufacture</th>
                    <th>Vehicle Valuer</th>
                    <th>Vehicle Undergone Valuation </th>
                    <th>Vehicle Type</th>
                    <th>Vehicle Model</th>
                    <th>Vehicle Make</th>
                    <th>Valuation Value</th>
                    <th>Tracking System Installed</th>
                    <th>Comprehensive Rate {`%`}</th>
                    <th>Third Party Rate</th>
                    <th>Product Type</th>
                    <th>Stamp Duty</th>
                  
                    <th>IRA Levy {`%`}</th>
                    <th>Cover Start Date</th>
                 
                    <th>Car Alarm Installed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{vehicleDetails.year_of_manufacture || "N/A" }</td>
                    <td>{vehicleDetails.vehicle_valuer || "N/A" }</td>
                    <td>{vehicleDetails.vehicle_undergone_valuation || "N/A" }</td>
                    <td>{vehicleDetails.vehicle_type || "N/A" }</td>
                    <td>{vehicleDetails.vehicle_model || "N/A" }</td>
                    <td>{vehicleDetails.vehicle_make || "N/A" }</td>
                    <td>{currencyCode + " " + vehicleDetails.valuation_value.toLocaleString() || "N/A" }</td>

                    <td>{vehicleDetails.tracking_system_Installed || "N/A" }</td>


                    <td>{vehicleDetails.comprehensive_rate || "N/A" }</td>
                    <td>{currencyCode + " " + vehicleDetails.third_party_rate.toLocaleString() || "N/A" }</td>
                    <td>{vehicleDetails.product_type || "N/A" }</td>
                    <td>{currencyCode + " " + vehicleDetails.stamp_duty.toLocaleString() || "N/A" }</td>
                    <td>{vehicleDetails.ira_levy || "N/A" }</td>
                    <td>{vehicleDetails.cover_start_date || "N/A" }</td>
                    <td>{vehicleDetails.car_alarm_installed || "N/A" }</td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <div className={classes.policieswrapper}>No vehicle details Found</div>
    );
  }
  
}

export default ViewVehicleDetailsInc