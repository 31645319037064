import React from 'react'
import QuotesCardInc from '../../../../../shared-ui/QuotesCardInc'



const Quotes = (props) => {

  return (
      <QuotesCardInc
        data={props.quotes}
        loader={props.loader}
      />

  )
}

export default Quotes