import React from 'react'
import PremiumRatesTable from './Components/PremiumRatesTable/PremiumRatesTable'
import LeviesTables from './Components/LeviesTables/LeviesTables'
import DutiesTable from './Components/DutiesTable/DutiesTable'
import AdditionalBenefitsTable from './Components/AdditionalBenefitsTable/AdditionalBenefitsTable'
const IncMotorConfigs = (props) => {
  return (
    <>
      <PremiumRatesTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <LeviesTables
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <DutiesTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <AdditionalBenefitsTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
    </>
  )
}

export default IncMotorConfigs