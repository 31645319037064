import React , { useState, useEffect } from 'react'
import TableInc from '../../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../../lib/requests.es6';

const AdditionalBenefitsTable = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const { additional_benefits } = props.membership_configs.content;

      const mappedData = additional_benefits.map(benefit => ({
        name: benefit.name,
        amount: benefit.amount,
      }));


      setData(mappedData);
    }
  }, [props.membership_configs]);

  const updateMembershipConfigs = async (newData) => {

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        additional_benefits: newData
      }
    }
    console.log("payload", payload)
   await updateExtendedConfig(payload)
   window.location.reload(true);
  };

  return (
    <TableInc
    title="Additional Benefits"
    columns={[
      { title: 'name', field: 'name' },
      { title: 'Amount ', field: 'amount' },
    ]}
    data={data}
    options={{
      addRowPosition: 'first',
      showTitle: true,
      actionsColumnIndex: -1,
      pageSize: data.length > 0 ? data.length : 5, // Show 5 rows or based on data length
    }}
    editable={{
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          console.log("New Data Added:", newData);

          const newDataArray = [...data, newData];
          console.log("newDataArray", newDataArray)
          setData(newDataArray);
          updateMembershipConfigs(newDataArray);
          resolve();

        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          console.log("Row Updated:", newData);
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          newData.benefit_amount = newData.benefit_amount * 100;
          dataUpdate[index] = newData;
          setData(dataUpdate);
          updateMembershipConfigs(dataUpdate);
          resolve();
        }),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          console.log("Row Deleted:", oldData);
          const dataDelete = [...data];
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          setData(dataDelete);
          updateMembershipConfigs(dataDelete);
          resolve();
          resolve();
        }),
    }}
  />
  )
}

export default AdditionalBenefitsTable