import React from 'react'
import { Box, Grid, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import SkeletonWrapper from '../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner, getPartnerGuid, hasAuthourity } from '../../lib/access.es6';
import { formatDateLocale } from '../../lib/utils.es6';
import PoliciesDependantsInc from '../PoliciesDependantsInc';
import ViewLoanDetailsInc from '../ViewLoanDetailsInc';
import ViewVehicleDetailsInc from '../ViewVehicleDetailsInc';

const ITEM_HEIGHT = 48;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const QuotesCardInc = (props) => {
  const classes = useStyle();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedQuote, setSelectedQuote] = React.useState(null)
  const [selectedQuoteAction, setSelectedQuoteAction] = React.useState(null)

  const [openViewLoanDetails, setOpenViewLoanDetails] = React.useState(false);
  const [selectedQuoteDetails, setSelectedQuotesDetails] = React.useState(null)

  const [openViewVehicleDetails, setOpenViewVehicleDetails] = React.useState(false);

  const [expanded, setExpanded] = React.useState(false);


  // Convert data to array if it's an object, or use it directly if it's already an array
  const quotes = Array.isArray(props.data) ? props.data : (props.data ? [props.data] : []);


  const dataForDisplay = expanded ? quotes : quotes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2);

  const openQuoteAction = Boolean(anchorEl);

  const handleOpenMenu = (event, quote) => {
    setAnchorEl(event.currentTarget);
    setSelectedQuoteAction(quote);
  };

  const handleClickOpen = (quote) => {
    setSelectedQuote(quote);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };



  const handleClickOpenViewLoanDetails = (Quote) => {
    setSelectedQuotesDetails(Quote);
    setOpenViewLoanDetails(true);
  };

  const handleCloseViewLoanDetails = () => {
    setAnchorEl(null);
    setOpenViewLoanDetails(false);
  }


  const handleClickOpenViewVehicleDetails = (Quote) => {
    setSelectedQuotesDetails(Quote);
    setOpenViewVehicleDetails(true);
  };

  const handleCloseViewVehicleDetails = () => {
    setAnchorEl(null);
    setOpenViewVehicleDetails(false);
  }


  const currencyCode = getPartner().country.currency_code

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }





  if (dataForDisplay.length > 0) {
    const parnterGuid = getPartnerGuid();

    return (
      <>


        {
          dataForDisplay.map((quote, index) => (

            (['incl_motor'].includes(parnterGuid)) ? <>
              <Grid container className={classes.policyWrapper} key={index}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{quote.additional_details.vehicle_registration_number || "N/A"}</h6>
                          <p>Vehicle Registration Number </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                          <Box display="flex" justifyContent="flex-end">
                            <ul>
                              <li className={quote.active === true ? classes.activePill : classes.inactivePill}> <span>{quote.active === true ? `Active` : `Inactive`}</span></li>
                              <li className={classes.actionbtnpolicy}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={openQuoteAction ? 'long-menu' : undefined}
                                  aria-expanded={openQuoteAction ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) => handleOpenMenu(event, quote)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {
                                  selectedQuoteAction && selectedQuoteAction.guid === quote.guid && <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openQuoteAction}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                    }}
                                      onClick={() => { setAnchorEl(null); handleClickOpenViewVehicleDetails(quote) }}
                                    >View More Details</MenuItem>


                                  </Menu>


                                }

                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Body Description </p>
                          <h6> {quote.additional_details.body_description || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> Chasis Number</p>
                      <h6> {quote.additional_details.chasis_number || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Color</p>
                      <h6> {quote.additional_details.color || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Engine Number</p>
                      <h6> {quote.additional_details.engine_number || "N/A"}</h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={6} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>Fuel Type</p>
                              <h6> {quote.additional_details.fuel_type || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <p> Sitting Capabilities</p>
                          <h6> {quote.additional_details.sitting_capabilities || "N/A"}</h6>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyBalance}>
                        <p>Years With Driving Licence</p>
                        <h6> {quote.additional_details.years_with_driving_licence || "N/A"}</h6>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


            </> :
              <Grid container className={classes.policyWrapper} key={index}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{quote.quotation_number || "N/A"}</h6>
                          <p> Quotation Number</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                          <Box display="flex" justifyContent="flex-end">
                            <ul>
                              <li className={quote.active === true ? classes.activePill : classes.inactivePill}> <span>{quote.active === true ? `Active` : `Inactive`}</span></li>
                              <li className={classes.actionbtnpolicy}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={openQuoteAction ? 'long-menu' : undefined}
                                  aria-expanded={openQuoteAction ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) => handleOpenMenu(event, quote)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {
                                  selectedQuoteAction && selectedQuoteAction.guid === quote.guid && <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openQuoteAction}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                    }}
                                      onClick={() => { setAnchorEl(null); handleClickOpen(quote) }}
                                    >View Dependants</MenuItem>

                                    {
                                      (['realpeopleguid'].includes(parnterGuid)) && <MenuItem style={{
                                        cursor: "pointer", fontFamily: "Open Sans",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#000000",
                                        display: hasAuthourity('VIEW_REAL_PEOPLE_QUOTES') ? 'block' : 'none'

                                      }} onClick={() => { setAnchorEl(null); handleClickOpenViewLoanDetails(quote) }} >View Loan Detail</MenuItem>
                                    }

                                  </Menu>


                                }

                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Start Date </p>
                          <h6> {formatDateLocale(quote.start_date) || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> End Date</p>
                      <h6>{formatDateLocale(quote.end_date) || "N/A"}
                      </h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Lapse Period</p>
                      <h6>{quote.lapse_period * quote.premium_cardinality + " " + quote.premium_granularity || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Created At</p>
                      <h6>{
                        formatDateLocale(quote.created_at) || "N/A"
                      }</h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={6} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>status</p>
                              <h6>{quote.status || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <p> Branch</p>
                          <h6>{quote.branch || "N/A"}</h6>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyBalance}>
                        <p>Premiums</p>
                        <h6>{`${currencyCode} ${quote.total_premium_amount_in_cents / 100} per ${quote.premium_cardinality} ${quote.premium_granularity}` || "0.00"}   </h6>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>



          ))
        }



        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              dataForDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>



        {/*dependants dialog box */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="dependants-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleClose}>
            Dependants
          </BootstrapDialogTitle>
          <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleClose}>
          </BootstrapDialogTitle>
          <DialogContent >
            <PoliciesDependantsInc quote={selectedQuote} />
          </DialogContent>
        </BootstrapDialog>
        {
          (['realpeopleguid'].includes(parnterGuid)) && <BootstrapDialog
            onClose={handleCloseViewLoanDetails}
            aria-labelledby="dependants-dialog-title"
            open={openViewLoanDetails}
          >
            <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleCloseViewLoanDetails}>
              Loans Details
            </BootstrapDialogTitle>
            <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleCloseViewLoanDetails}>
            </BootstrapDialogTitle>
            <DialogContent >
              <ViewLoanDetailsInc LoanDetails={selectedQuoteDetails} />
            </DialogContent>
          </BootstrapDialog>
        }

{
          (['incl_motor'].includes(parnterGuid)) && <BootstrapDialog
            onClose={handleCloseViewVehicleDetails}
            aria-labelledby="dependants-dialog-title"
            open={openViewVehicleDetails}
          >
            <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleCloseViewVehicleDetails}>
              Vehicle Details
            </BootstrapDialogTitle>
            <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleCloseViewVehicleDetails}>
            </BootstrapDialogTitle>
            <DialogContent >
              <ViewVehicleDetailsInc vehicleDetails={selectedQuoteDetails} />
            </DialogContent>
          </BootstrapDialog>
        }

      </>
    )


  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default QuotesCardInc