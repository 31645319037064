import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { hashHistory } from 'react-router';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import Transitions from '../../ui-component/extended/Transitions';


// assets
import SettingIcon from './SettingIcon';
import AccountSettingIcon from './AccountSettingIcon'
import LogoutIcon from './LogoutIcon';
import { getUserFullname } from '../../../../lib/access.es6';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({ logOutUserHandler }) => {
    const theme = useTheme();


    const [selectedIndex,
        // setSelectedIndex
    ] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    // const handleListItemClick = (event, index, route = '') => {
    //     setSelectedIndex(index);
    //     handleClose(event);


    // };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const viewAccountSettings = () => {
        hashHistory.push('admin/profile');
    }

    const firstCharOfUsername = (getUserFullname() || 'U')[0].toUpperCase()

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    marginTop: "10px",
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: "rgb(33, 150, 243)",
                    backgroundColor: "rgb(227, 242, 253)",
                    border: "1px solid rgb(227, 242, 253)",
                    color: "rgb(33, 150, 243)",
                    cursor: "pointer",
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: `#032a37`,
                        background: `#032a37 !important`,
                        color: "#fff",
                        '& svg': {
                            stroke: "#fff"
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        //src={User1}
                        sx={{
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            bgcolor: "#fba92d",
                            color: "#fff"
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="#fff"
                    ><span style={{ color: "#fff" }}>{firstCharOfUsername}</span></Avatar>
                }
                label={<SettingIcon />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography sx={{
                                                        fontFamily: "Open Sans",
                                                        fontStyle: "normal",
                                                        fontWeight: "700",
                                                        fontSize: "16px",
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.001em",
                                                    }} variant="h4">Welcome,</Typography>
                                                    <Typography sx={{
                                                        fontFamily: "Open Sans",
                                                        fontStyle: "normal",
                                                        fontWeight: "700",
                                                        fontSize: "16px",
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.001em",
                                                    }} component="span" variant="h4" >
                                                        {getUserFullname()}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Divider sx={{ marginTop: "20px" }} />
                                        </Box>

                                        <Box sx={{ p: 2, pt: 0 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    selected={selectedIndex === 0}
                                                    onClick={viewAccountSettings}
                                                    sx={{
                                                        '&.Mui-selected': {
                                                            backgroundColor: '#032a37',
                                                            color: '#fff',
                                                            fontFamily: "Lato",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            letterSpacing: "0.001em",
                                                            "& svg": {
                                                                color: '#fff',
                                                            }
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: '#032a37 !important',
                                                            color: '#fff',
                                                            "& svg": {
                                                                color: '#fff',
                                                            }
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        style={{
                                                            minWidth: '40px'
                                                        }}
                                                    >
                                                        <AccountSettingIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                Account Settings
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    selected={selectedIndex === 1}
                                                    onClick={() => logOutUserHandler()}

                                                    sx={{
                                                        '&.Mui-selected': {
                                                            backgroundColor: '#032a37',
                                                            color: '#fff',
                                                            fontFamily: "Lato",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            letterSpacing: "0.001em",
                                                            "& svg": {
                                                                color: '#fff',
                                                            }
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: '#032a37 !important',
                                                            color: '#fff',
                                                            "& svg": {
                                                                color: '#fff',
                                                            }
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        style={{
                                                            minWidth: '40px'
                                                        }}
                                                    >
                                                        <LogoutIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                Logout
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
