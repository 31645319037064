import React from 'react';
import Grid from "@material-ui/core/Grid";
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';
import { useStyle } from "./index.styles";
// import { ProductTypes } from '../../helpers';

export function SelectProductType({ setProductType, productType, setProductDetails, products }) {
  const classes = useStyle();


  const handleSelect = (value) => {
    setProductType(value.name)
    setProductDetails(value)
    console.log("product type", value)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}><h2 style={{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "20px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
        color: "#000000",
        textAlign: "center"
      }}>Please select product type</h2></Grid>
      {
       // console.log("motor_configs", motor_configs)
       products.map((config, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
          <div className={`${classes.productWrapper} ${productType === config.name ? 'selected' : ''}`}
            onClick={() => {
              handleSelect(config);
             // setProductDetails(config)
            }}>
            <CarRentalOutlinedIcon
              sx={{
                color: "#fff",
                marginTop: "70px",
              }}
            />
            <div> <h3>{config.name}</h3></div>
          </div>
        </Grid>
        ))
      }
      {/* <Grid item xs={12} sm={6} md={6}>
        <div className={`${classes.productWrapper} ${productType === ProductTypes.MotorComprehensive ? 'selected' : ''}`}
          onClick={() => handleSelect(ProductTypes.MotorComprehensive)}>
          <CarRentalOutlinedIcon
            sx={{
              color: "#fff",
              marginTop: "70px",
            }}
          />
          <div> <h3>{ProductTypes.MotorComprehensive}</h3></div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} >
        <div className={`${classes.productWrapper} ${productType === ProductTypes.ThirdParty ? 'selected' : ''}`} onClick={() => handleSelect(ProductTypes.ThirdParty)}>
          <TimeToLeaveOutlinedIcon
            sx={{
              color: "#fff",
              marginTop: "70px",
            }}
          />
          <div><h3>{ProductTypes.ThirdParty}</h3></div>
        </div>
      </Grid> */}

    </Grid>
  )
}
