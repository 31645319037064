import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { getPartnerGuid, getUserEmail, getUserFullname } from '../../../../../../lib/access.es6';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';


import posthog from 'posthog-js';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { carMakes } from './CarMakes';
import { ToyotaModels } from './ToyotaModels';
import { Valuers } from './Valuers';


const Adddependant = (props) => {
  const classes = useStyle();

  const [memberAdded, setMemberAdded] = useState(false)


  const formRef = useRef(null);

  //const [vehicleMake, setVehicleMake] = useState([])

  const [vehicleType, setVehicleType] = useState('')
  const [vehicleMake, setVehicleMake] = useState('')
  const [vehicleModel, setVehicleModel] = useState('')
  const [yearOfManufacture, setYearOfManufacture] = useState('')
  const [coverStartDate, setCoverStartDate] = useState('')
  const [vehicleUndergoneValuation, setVehicleUndergoneValuation] = useState('')
  const [valuationValue, setValuationValue] = useState('')
  const [vehicleValuer, setVehicleValuer] = useState('')
  const [carAlarmInstalled, setCarAlarmInstalled] = useState('')
  const [trackingSystemInstalled, setTrackingSystemInstalled] = useState('')
  const [comprehensiveRate, setComprehensiveRate] = useState('')
  const [ThirdPartyAmount, setThirdPartyAmount] = useState('');
  const [iraLevy, setIraLevy] = useState(0);
  const [stampDuty, setStampDuty] = useState(0)

  useEffect(() => {
    const duty = props.productDetails.membership_configs.content.duties.find(duty => duty.name === "Stamp Duty");
    const levy = props.productDetails.membership_configs.content.levies.find(levy => levy.name === "IRA Levy");

    if (duty) {
      setStampDuty(parseFloat(duty.rate));
      console.log("stampDuty", duty.rate)
    }

    if (levy) {
      //setIraLevy(levy.rate);
      setIraLevy(parseFloat(levy.rate) / 100);
      console.log("iraLevy", parseFloat(levy.rate) / 100)
    }
  }, [props.productDetails])



  const handleVehicleType = (event) => {
    setVehicleType(event.target.value)
    console.log("vehiclemale", event.target.value)
  }

  //console.log("props.productDetails",    props.productDetails)


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'vehicleMake') {
      setVehicleMake(value)
    }
    if (name === 'vehicleModel') {
      setVehicleModel(value)
    }
    if (name === 'yearOfManufacture') {
      setYearOfManufacture(value)
    }
    if (name === 'coverStartDate') {
      setCoverStartDate(value)
    }

    if (name === 'vehicleUndergoneValuation') {
      setVehicleUndergoneValuation(value)
    }

    if (name === 'valuationValue') {
      setValuationValue(value)
    }

    if (name === 'vehicleValuer') {
      setVehicleValuer(value)
    }

    if (name === 'carAlarmInstalled') {
      setCarAlarmInstalled(value)
    }

    if (name === 'TrackingSystemInstalled') {
      setTrackingSystemInstalled(value)
    }
    if (name === 'comprehensiveRate') {
      setComprehensiveRate(value)
    }
    if (name === 'ThirdPartyAmount') {
      setThirdPartyAmount(value)
    }

  };
  const reset = () => {
    setVehicleMake('');
    setVehicleModel('');
    setYearOfManufacture('');
    setCoverStartDate('');
    setVehicleUndergoneValuation('');
    setValuationValue('');
    setVehicleValuer('');
    setCarAlarmInstalled('');
    setTrackingSystemInstalled('')
    setComprehensiveRate('')
  }

  const generatePayload = (productType) => {
    const benfit_uuid = uuidv4();
    const isComprehensive = productType === "Motor Comprehensive";

    const benefits = isComprehensive
      ? [
        {
          type: "MOTOR COMPREHENSIVE",
          cover_amount_in_cents: 1000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Excess Protector",
          cover_amount_in_cents: 10000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Forced ATM withdrawal",
          cover_amount_in_cents: 20000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Loss of a spare wheel",
          cover_amount_in_cents: 30000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "AA membership",
          cover_amount_in_cents: 40000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Political Violence and Terrorism",
          cover_amount_in_cents: 50000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Courtesy Car",
          cover_amount_in_cents: 60000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
        {
          type: "Windscreen",
          cover_amount_in_cents: 70000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
      ]
      : [
        {
          type: "THIRD PARTY COVER",
          cover_amount_in_cents: 1000,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 1000,
          uuid: benfit_uuid,
          is_initialized: true,
        },
      ];


    const payload = {
      additional_details: {
        product_type: productType,
        vehicle_type: vehicleType, // Include the selected vehicle type
        vehicle_make: vehicleMake,
        vehicle_model: vehicleModel,
        year_of_manufacture: yearOfManufacture,
        cover_start_date: coverStartDate,
        vehicle_undergone_valuation: vehicleUndergoneValuation,
        valuation_value: valuationValue,
        vehicle_valuer: vehicleValuer,
        car_alarm_installed: carAlarmInstalled,
        tracking_system_Installed: trackingSystemInstalled,
        comprehensive_rate: comprehensiveRate || "N/A",
        third_party_rate: ThirdPartyAmount || "N/A",
        stamp_duty: stampDuty,
        ira_levy: iraLevy
      },
      benefits,
      premium_amount_in_cents: 0, // Placeholder for now
      "relationship": "Principal",
    };

    // Calculate the premium
    payload.premium_amount_in_cents = Math.round(
      calculateBasicPremium(payload) * 100
    ); // Convert to cents

    return payload;

  };

  function calculateBasicPremium(payload) {
    const { additional_details } = payload;
    const { product_type, valuation_value, comprehensive_rate, stamp_duty, ira_levy, third_party_rate } = additional_details;

    // Convert valuation_value to a number
    const vehicleValue = parseFloat(valuation_value);

    if (product_type === "Motor Comprehensive") {
      // Calculate Comprehensive Premium
      const basicPremium = (vehicleValue * comprehensive_rate) / 100;
      const iraLevy = basicPremium * ira_levy;
      return basicPremium + iraLevy + stamp_duty; // Add IRA levy and stamp duty
    } else if (product_type === "Third Party") {
      // Fixed Third-Party Premium
      // Calculate Third Party Premium
      const thirdPartyPremium = parseFloat(third_party_rate); // Ensure the rate is provided and parsed correctly
      if (isNaN(thirdPartyPremium)) {
        throw new Error("Invalid third-party rate");
      }
      const iraLevy = thirdPartyPremium * ira_levy; // IRA Levy (2%)
      return thirdPartyPremium + iraLevy + stamp_duty; // Add IRA Levy and Stamp Duty
    } else {
      throw new Error("Invalid product type or third-party selection");
    }
  }


  //console.log("props.productDetails", props.productDetails)

  console.log("dependant", props.dependant)



  const addDependant = () => {



    if (!vehicleType) {
      alert("Please select a vehicle type.");
      return;
    }
    const payload = generatePayload(props.productType);
    console.log("payload: ", payload);

    const tempDependants = props.dependants;
    tempDependants.push(payload);
    props.setDependants(tempDependants);
    setMemberAdded(true)
    reset()




  }



  const handleDependantCreatedSuccessfully = () => {
    setMemberAdded(false)
    posthog.capture('add_dependant_success_monamodi', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  }
  const minRate = Number(
    props.productDetails &&
    props.productDetails.membership_configs &&
    props.productDetails.membership_configs.content &&
    props.productDetails.membership_configs.content.premium_rates &&
    props.productDetails.membership_configs.content.premium_rates[0] &&
    props.productDetails.membership_configs.content.premium_rates[0].min_rate
  );
  const maxRate = Number(
    props.productDetails &&
    props.productDetails.membership_configs &&
    props.productDetails.membership_configs.content &&
    props.productDetails.membership_configs.content.premium_rates &&
    props.productDetails.membership_configs.content.premium_rates[0] &&
    props.productDetails.membership_configs.content.premium_rates[0].max_rate
  );
  return (
    <>

      <AlertDialog
        success
        show={memberAdded}
        size="sm"
        title={'Details added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => {
            handleDependantCreatedSuccessfully()
            props.viewMembers()
          }} style={{ background: 'green', }}>view Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'details  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      {/* <AlertDialog
        show={props.showAddDependantErrorAlert}
        danger
        title={'Error creating member'}
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'error'}
      </AlertDialog> */}

      <ValidatorForm onSubmit={() => addDependant()} ref={formRef} >
        <section className={classes.quoteinputwrapper}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.detailstitle}>
                <h3>Lets add Details</h3>
                {/* {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                } */}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.vehicleTypeCheck}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Choose Vehicle Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={vehicleType}
                    onChange={handleVehicleType}
                  >
                    <FormControlLabel value="Motorcycle" control={<Radio />} label="Motorcycle" />
                    <FormControlLabel value="Car" control={<Radio />} label="Car" />

                  </RadioGroup>
                </FormControl>
                {/* <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={isCitizenCheck}
                    onChange={handleIsCitizenChange}
                    control={<Checkbox size="small" />}
                    label="Is Non Citizen?"
                    labelPlacement="start"
                  />
                </FormGroup> */}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-vehicle-make"
                id="select-vehicle-make"
                value={vehicleMake}
                name='vehicleMake'
                type="text"
                label="Vehicle Make"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select vehicle make']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {
                  carMakes.map((car, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={car.title}>{car.title}</MenuItem>
                  ))
                }

              </SelectValidator>

            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-vehicle-model"
                id="select-vehicle-model"
                value={vehicleModel}
                name='vehicleModel'
                type="text"
                label="Vehicle Model"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select vehicle Model']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {
                  ToyotaModels.map((model, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={model.title}>{model.title}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="yearOfManufacture"
                fullWidth
                label="Year Of Manufacture"
                name='yearOfManufacture'
                value={yearOfManufacture}
                type='number'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required", "matchRegexp:^\\d{4}$"]}
                errorMessages={[
                  "Cover start year is required",
                  "Invalid year format",
                ]}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1900, // Set minimum year
                  max: new Date().getFullYear(), // Set maximum year to current year
                  step: 1,
                }}

              />

            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="cover-start-date"
                label="Cover Start Date"
                name='coverStartDate'
                value={coverStartDate}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={["required"]}
                errorMessages={["Cover start date is required"]}
                style={{ width: "100%" }}
              />

            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-vehicle-undergone-valuation"
                id="select-vehicle-undergone-valuation"
                value={vehicleUndergoneValuation}
                name='vehicleUndergoneValuation'
                type="text"
                label="vehicle undergone valuation?"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select vehicle undergone valuation']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="Yes">Yes</MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="No">No</MenuItem>

              </SelectValidator>



            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="valuation-value"
                fullWidth
                label="Valuation Value"
                name='valuationValue'
                value={valuationValue}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-vehicle-valuer"
                id="select-vehicle-valuer"
                value={vehicleValuer}
                name='vehicleValuer'
                type="text"
                label="Who was your valuer?"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select vehicle valuer']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {
                  Valuers.map((val, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={val.valuer_name}>{val.valuer_name}</MenuItem>
                  ))
                }

              </SelectValidator>

            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-car-alarm-installed"
                id="select-car-alarm-installed"
                value={carAlarmInstalled}
                name='carAlarmInstalled'
                type="text"
                label=" car alarm installed?"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select  car alarm installed?']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="Yes">Yes</MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="No">No</MenuItem>

              </SelectValidator>

            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-Tracking-system-installed"
                id="select-Tracking-system-installed"
                value={trackingSystemInstalled}
                name='TrackingSystemInstalled'
                type="text"
                label=" Tracking system installed?"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select tracking system installed']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="Yes">Yes</MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="No">No</MenuItem>

              </SelectValidator>

            </Grid>

          </Grid>
        </section>


        <section className={classes.benefitswrapper}>

          <hr />

          <section className={classes.premiumRates}>
            <div className={classes.detailstitle} style={{
              marginBottom: "20px",
              marginTop: "20px"
            }} >
              {
                props.productType === "Motor Comprehensive" ? (<h3>Choose Motor Comprehensive Premium  Rate to Apply</h3>) : (<>Choose Third Party Premium  to Apply</>)
              }

            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                {
                  props.productType === "Motor Comprehensive" ? (<>
                    {/* <SelectValidator
                      labelId="select-Motor-Comprehensive"
                      id="select-Motor-Comprehensive"
                      value={comprehensiveRate}
                      name='comprehensiveRate'
                      type="text"
                      label=" Select motor comprehensive percentage to apply"
                      onChange={handleChange}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select motor comprehensive rate']}
                      style={{ width: "100%" }}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                    >
                      {

                        props.productDetails.
                          membership_configs
                          .content.premium_rates.map((rate, index) => (<MenuItem
                            key={index}
                            sx={{
                              fontFamily: "Open Sans",
                              fontStyle: "normal",
                              fontWeight: 300,
                              fontSize: "12px",
                              lineHeight: "16px",
                              letterSpacing: "0.005em",
                              color: "#000000",
                            }} value={rate}>{rate}</MenuItem>))
                      }

                    </SelectValidator> */}
                    <TextValidator
                      id="comprehensiveRate"
                      label="Enter rate to apply"
                      name='comprehensiveRate'
                      value={comprehensiveRate}
                      onChange={handleChange}
                      fullWidth
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      validators={[
                        "required",
                        "isFloat",
                        `minNumber:${minRate}`, // Use min_rate from the object
                        `maxNumber:${maxRate}`, // Use max_rate from the object
                      ]}
                      errorMessages={[
                        "Rate is required",
                        "Invalid number",
                        `Rate must be at least ${minRate}`, // Use min_rate in the error message
                        `Rate must be no more than ${maxRate}`, // Use max_rate in the error message
                      ]}
                      style={{ width: "100%" }}
                    />

                  </>) : (<>
                    <SelectValidator
                      labelId="select-third-party"
                      id="select-third-party"
                      value={ThirdPartyAmount}
                      name='ThirdPartyAmount'
                      type="text"
                      label=" Select third party amount to apply"
                      onChange={handleChange}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select third party amount']}
                      style={{ width: "100%" }}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                    >
                      {

                        props.productDetails.premium_rates.map((rate, index) => (<MenuItem
                          key={index}
                          sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.005em",
                            color: "#000000",
                          }} value={rate}>{rate.toLocaleString()}</MenuItem>))
                      }

                    </SelectValidator>


                  </>)
                }

              </Grid>
            </Grid>


          </section>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add Details
                </Button>

                <Button variant="contained" onClick={() => {
                  props.viewMembers()
                }} style={{ background: 'green', }}>Cancel</Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm >
    </>
  )
}

export default Adddependant