import * as React from 'react';
import { hashHistory } from 'react-router';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Audit from "../../../../../containers/Audits/index";

//components
import PolicyDetails from '../PolicyDetails/PolicyDetails';
import Quotes from '../PolicyDetails/Quotes';
import Documents from '../PolicyDetails/Documents';
import Notes from '../PolicyDetails/Notes';
import Payments from '../PolicyDetails/Payments';
import Premiums from '../PolicyDetails/Components/Premiums/Premiums';
import Claims from '../PolicyDetails/Components/Claims/Claims'
import { hasAuthourity } from '../../../../../lib/access.es6';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NavigationWrapper = (props) => {

  React.useEffect(() => {
    props.getCustomerDetails()
  }, [])


  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const viewCustomerDetail = (customer_guid) => {
    if (customer_guid) {
      hashHistory.push('admin/customer_status/' + customer_guid);
    }
  }
  const makeAClaim = (customer_guid) => {
    hashHistory.push(`admin/claim-initiation-complex/${customer_guid}`);

  }

  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: "auto" }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "18%" }}
          className={classes.tabs}
        >
          <Tab label="Policy" {...a11yProps(0)} />
          <Tab label="Vehicle Details" {...a11yProps(1)} />
          <Tab label="Documents" {...a11yProps(2)} />
          <Tab label="Payments"  {...a11yProps(3)} />
          <Tab label="Premiums" {...a11yProps(4)} />
       
          <Tab label="Notes" {...a11yProps(5)} />
          <Tab label="Claims" {...a11yProps(6)} />
          <Tab label="Audits" {...a11yProps(7)} />


        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Policy</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => viewCustomerDetail(props.policy.customer_guid)}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px"

                }}
              >
                <span style={{ color: "#000000" }}>{"View Customer Details"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }}
                />
              </ButtonInc>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <PolicyDetails
                policy={props.policy}
                setPolicyToDownloadDocumentFor={props.setPolicyToDownloadDocumentFor}
                toggleOpenCancelPolicyDialog={props.toggleOpenCancelPolicyDialog}
                toggleUpdateBeneficaryDialog={props.toggleUpdateBeneficaryDialog}
                toggleUpgradeDialog={props.toggleUpgradeDialog}
                setQuotationToReCreate={props.setQuotationToReCreate}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Vehicle Details</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Quotes
                quotes={props.policy.quotation}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Documents</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

              <ButtonInc
                onClick={props.toggleOpenPolicyDocumentDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                }}
              >
                <span style={{ color: "#000000" }}>{"Add Policy Document"}</span>
                <AddOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Documents
                documents={props.policy.policy_onboarding_documents}
                setDocumentToDelete={props.setDocumentToDelete}
                policyActiveState={props.policyActiveState}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={3} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Payments</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={props.toggleAddPaymentDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                }}
              >
                <span style={{ color: "#000000" }}>{"Add Policy Payment"}</span>
                <AddOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Payments
                getAllPayments={props.getAllPayments}
                payments={props.payments}
                loader={props.loader}
              />
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={4} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Premiums</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Premiums

                getPremiums={props.getPremiums}
                premiums={props.premiums}
                premiumsloader={props.premiumsloader}
                policyGuid={props.policyGuid}
              />
            </Grid>
          </Grid>
        </TabPanel>



        <TabPanel value={value} index={5} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Notes</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={props.toggleOpenPolicyNoteDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                }}
              >
                <span style={{ color: "#000000" }}>{"Add Policy Note"}</span>
                <AddOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Notes
                notes={props.policy.notes}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={6} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Claims</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                 onClick={() => makeAClaim(props.customer_guid)}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px"

                }}
              >
                <span style={{ color: "#000000" }}>{"Make A Claim"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }}
                />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Claims
                policyGuid={props.policyGuid}
                getClaims={props.getClaims}
                claims={props.claims}
                claimsloader={props.claimsloader}
                getCustomerPolicies={props.getCustomerPolicies}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={7} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1> Audits</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Audit 
                entity_guid={props.policyGuid} 
              />
            </Grid>
          </Grid>
        </TabPanel>


      </Box>
    </>
  )
}

export default NavigationWrapper