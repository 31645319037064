import React from 'react'
import { SelectProductType } from './SelectProductType';
import CreateQuote from './CreateQuote/Createquote';
export const CreateQuoteSwitch = (props) => {
  const [productType, setProductType] = React.useState('')
  const [productDetails, setProductDetails] = React.useState([])

  const ProductTypes = {
    MotorComprehensive: 'Motor Comprehensive',
    ThirdParty: 'Third Party',
  };

  // useEffect(() => {
 
  // }, [props.products])
  




  if (!productType) {
    return <SelectProductType setProductType={setProductType} productType={productType} productDetails={productDetails} setProductDetails={setProductDetails} products={props.products} />

  } else {
    switch (productType) {
      case ProductTypes.MotorComprehensive:
        return <CreateQuote {...props} productType={productType} setProductType={setProductType} productDetails={productDetails} setProductDetails={setProductDetails} createQuoteError={props.createQuoteError} />

      case ProductTypes.ThirdParty:
        return <CreateQuote {...props} productType={productType} setProductType={setProductType} productDetails={productDetails} setProductDetails={setProductDetails} createQuoteError={props.createQuoteError} />

      default:
        return <SelectProductType setProductType={setProductType} productType={productType} productDetails={productDetails} setProductDetails={setProductDetails} />
    }

  }
}
