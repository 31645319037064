import React, { useState, useEffect } from 'react'
import TableInc from '../../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../../lib/requests.es6';

const PremiumRatesTable = (props) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const { premium_rates } = props.membership_configs.content;

      const mappedData = premium_rates.map(rate => ({
        min_rate: rate.min_rate,
        max_rate: rate.max_rate,
      }));


      setData(mappedData);
    }
  }, [props.membership_configs]);

  const updateMembershipConfigs = async (newData) => {
    // const adjustedData = newData.map(rate => ({
    //   ...rate,
    //   rate: (rate.rate / 100)
    // }));

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        premium_rates: newData
      }
    }
    console.log("payload", payload)
    await updateExtendedConfig(payload)
   window.location.reload(true);
  };

  return (
    <TableInc
    title="Premium  Rates (%)"
    columns={[
      { title: 'min Rate (%)', field: 'min_rate' },
      { title: 'max Rate  (%)', field: 'max_rate' },
    ]}
    data={data}
    options={{
      addRowPosition: 'first',
      showTitle: true,
      actionsColumnIndex: -1,
      pageSize: data.length > 0 ? data.length : 5, // Show 5 rows or based on data length
    }}
    editable={{
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          console.log("New Data Added:", newData);

          const newDataArray = [...data, newData];
          console.log("newDataArray", newDataArray)
          setData(newDataArray);
          updateMembershipConfigs(newDataArray);
          resolve();

        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          console.log("Row Updated:", newData);
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          newData.benefit_amount = newData.benefit_amount * 100;
          dataUpdate[index] = newData;
          setData(dataUpdate);
          updateMembershipConfigs(dataUpdate);
          resolve();
        }),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          console.log("Row Deleted:", oldData);
          const dataDelete = [...data];
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          setData(dataDelete);
          updateMembershipConfigs(dataDelete);
          resolve();
          resolve();
        }),
    }}
  />
    // <TableInc
    //   title="Premium  Rates (%)"
    //   columns={[
    //     { title: 'Rates ', field: 'rate', },

    //   ]}
    //   data={data}
    //   options={{
    //     addRowPosition: 'first',
    //     showTitle: true,
    //     actionsColumnIndex: -1,
    //     pageSize: data.length,
    //   }}
    //   actionsColumnIndex={-1}
    //   editable={{
    //     onRowAdd: newData =>
    //       new Promise((resolve, reject) => {

    //         const newDataArray = [...data, newData];
    //         const newArray = convertArrayOfString(newDataArray)
    //         //setData(newDataArray);
    //         updateMembershipConfigs(newArray);
    //         resolve();
    //       }),
    //     onRowUpdate: (newData, oldData) =>
    //       new Promise((resolve, reject) => {
    //         const dataUpdate = [...data];
    //         const index = oldData.tableData.id;
    //         dataUpdate[index] = newData;
    //         //setData(dataUpdate);
    //         const newArray = convertArrayOfString(dataUpdate)
    //         updateMembershipConfigs(newArray);
    //         resolve();
    //       }),
    //     onRowDelete: oldData =>
    //       new Promise((resolve, reject) => {
    //         const dataDelete = [...data];
    //         const index = oldData.tableData.id;
    //         dataDelete.splice(index, 1);
    //         // setData(dataDelete);
    //         const newArray = convertArrayOfString(dataDelete)
    //         updateMembershipConfigs(newArray);
    //         resolve();
    //       }),
    //   }}
    // />
  )
}

export default PremiumRatesTable